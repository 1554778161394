<template>
  <div>
    <Nav :name="name" />
    <section id="home">
      <div id="presentation">
          <div class="text_presentation">
              <p> {{text_desc}}</p>
              <img :src="logoSrc" :style="{ width: logoWidth }" alt="logo">
          </div>
      </div>

      <div class="contact">
            <p>Pour me contacter : </p>
      </div>

      <div class="git_linkedin">
        <a class="git_linkedin_co" href="https://github.com/Gautias" target="_blank" rel="noopener">
            <img src="../assets/images/github.png" width="40px" height="40px" alt="logo_github">
        </a>
        <a href="https://www.linkedin.com/in/valentin-gaultier-8766b7199/" target="_blank" rel="noopener">
            <img src="../assets/images/linkedin.png" width="40px" height="40px" alt="logo_linkedin">
        </a>

        <a class="git_linkedin_co"  href="mailto:valentin.gaultier147@orange.fr" target="_blank" rel="noopener">
            <img src="../assets/images/email.png" width="40px" height="40px" alt="logo_linkedin">
        </a>

      </div>
      
  
      <!-- <svg viewBox="0 120 1440 140" style="transform: translateY(25px); position: relative; z-index: -1;">
            <path fill="#ecf0f1" fill-opacity="1" d="M0,192L48,197.3C96,203,192,213,288,229.3C384,245,480,267,576,250.7C672,235,768,181,864,181.3C960,181,1056,235,1152,234.7C1248,235,1344,181,1392,154.7L1440,128L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path>
      </svg> -->
    </section>

    
    <section id="services">

      <div class="header-container">
          <h1 class="portfolio-title">NOS EXPERTISES</h1>
          <p class="subtitle">Concept Génération</p>
          <hr class="divider" />
       </div>

      <div class="presta-container">
        <div
          v-for="(card, index) in cardss"
          :key="index"
          :class="['presta-card', card.customClass]">
          <div class="icon">
            <i :class="card.iconClass"></i>
          </div>
          <h3>{{ card.title }}</h3>
          <ul class="desctext">
            <li v-for="(item, idx) in card.items" :key="idx" v-html="item"></li>
          </ul>
        </div>
      </div>
    </section>


    <!-- card -->

    <section id="site">
       <div class="header-container">
          <h1 class="portfolio-title">NOS RÉALISATION</h1>
          <p class="subtitle">Concept Génération</p>
          <hr class="divider" />
       </div>

       <div class="cards-container">
          <Card
            v-for="(card, index) in cards"
            :key="index"
            :logo="card.logo"
            :title="card.title"
            :tags="card.tags"
            :description="card.description"
          />
       </div>
    </section>

    <Footer />

  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import Footer from "../components/Footer.vue";
import Card from "../components/Card.vue";
import GithubLogo from '@/assets/images/logo-innolive.png';
import mon_epicerie from '@/assets/images/logo_mon_epicerie.png';
import blondeau from '@/assets/images/blondeau.png';


export default {
  components:{
    Nav,
    Footer,
    Card,
  },
  methods:{
    redirectToLink(link){
      window.location.href =  link;
    }
  },
  data() {
    return {
      name: "Concept Génération",
      text_desc: "Découvrez Valentin Gaultier, développeur web à Romorantin spécialisé en applications web et mobiles, formateur. De la création de vitrines séduisantes à des sites e-commerce performants, il vous guide dans le monde numérique pour donner vie à vos projets ! ",
      logoSrc: "https://image.noelshack.com/fichiers/2024/46/5/1731666309-programming-valentin-removebg-preview.png",
      
      


      cardss: [
        {
          title: "Création de site internet",
          iconClass: "icon-web",
          items: ["Conception de site vitrine, blog ou e-commerce pour développer son activité."],
          customClass: "web-card", // Nom personnalisé pour la classe de cette carte
        },


        {
          title: "Refonte de site internet",
          iconClass: "icon-software",
          items: ["Nous analysons et optimisons votre site web pour qu'il corresponde pleinement à vos objectifs et aspirations, tout en valorisant les efforts et les résultats déjà accomplis."],
          customClass: "progiciel-card", // Nom personnalisé pour la classe de cette carte
        },

        {
          title: "Intégration web",
          iconClass: "icon-software",
          items: ["Transformez vos maquettes en sites web performants et responsives, parfaitement adaptés à vos besoins."],
          customClass: "progiciel-card", 
        },
        {
          title: "Maintenance Web",
          iconClass: "icon-mobile",
          items: ["Assurez le bon fonctionnement de votre site avec notre service de maintenance web fiable et réactif."],
          customClass: "mobile-card",
        },

        // {
        //   title: "Ajout de fonctionnalités",
        //   iconClass: "icon-mobile",
        //   items: ["Gestion d'hébérgement, garanti d'accessibilité, intégration régulière des mises à jour de sécurité"],
        //   customClass: "mobile-card", // Nom personnalisé pour la classe de cette carte
        // },
      ],


      cards: [

        {
          logo: GithubLogo,
          title: "INNOLIVE",
          tags: ["Site vitrine"],
          description: "Innolive, votre atout marketing en production audiovisuelle.",
        },
        {
          logo: mon_epicerie,
          title: "Mon épiceries bérichonnes",
          tags: ["E-ecommerce"],
          description: "Maison Chauveau et des produits de producteurs et d’artisans du Berry",

        },

        {
          logo: blondeau,
          title: "Blondeau Batiment",
          tags: ["Site vitrine", "Carreleur"],
          description: "Découvrez les prestations de votre carreleur en neuf ou en rénovation : Pose de carrelage, faïence, crédence et mosaïque Découpes",

        }
      ]

    };
  },
};
</script>

<style>
@import url(../assets/css/index.css);
@import url(../assets/css/card.css);
@import url(../assets/css/presta.css);

body{
  background-color:#17171f;
}





</style>
