<template>
  <div class="card">
    <img class="logo" :src="logo" alt="logo">
    <div class="content">
      <h3>{{ title }}</h3>
      <div class="tags">
        <span v-for="(tag, index) in tags" :key="index" class="tag">{{ tag }}</span>
      </div>
      <p>{{ description }}</p>
    </div>
  </div>
</template>

<script>

export default {
  name: "Card",
  props: {
    logo: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    tags: {
      type: Array,
      default: () => []
    },
    description: {
      type: String,
      required: true
    }
  }
};
</script>

<style src="../assets/css/card.css"></style>
