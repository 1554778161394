<template>
    <nav class="navbar" :class="{'responsive-nav': isResponsiveNavOpen}">
      <div class="navbar_left">
        <span>{{ name }}</span>
      </div>
      <div class="navbar_right">
        <ul :class="{'show-links': isResponsiveNavOpen}">
           <li><a href="#" @click.prevent="scrollToAcceuil">Accueil</a></li>
           <li><a href="#" @click.prevent="scrollToServices">Services</a></li>
           <li><a href="#" @click.prevent="scrollToSite">Sites réalisés</a></li>
            <li>
                <button class="nav_button">
                  <a  class="nav_button" href="mailto:valentin.gaultier147@orange.fr" target="_blank">Contactez moi !</a>   
                </button>
            </li>

         
        </ul>
        <div class="navbar_toggler" @click="toggleResponsiveNav">
          <span></span>
          <span></span>
          <span></span>
      </div>
      </div>
    </nav>
</template>


<script>
export default {
  props: {
    name: {
      type: String,
      required: true,
    },
  },
    methods: {

      scrollToAcceuil() {
      const homeSection = document.getElementById('home');
      if (homeSection) {
        homeSection.scrollIntoView({ behavior: 'smooth' });
      }
    },


      scrollToServices() {
      const servicesSection = document.getElementById('services');
      if (servicesSection) {
        servicesSection.scrollIntoView({ behavior: 'smooth' });
      }
    },

      scrollToSite() {
      const siteSection = document.getElementById('site');
      if (siteSection) {
        siteSection.scrollIntoView({ behavior: 'smooth' });
      }
    },
    
    toggleResponsiveNav() {
      this.isResponsiveNavOpen = !this.isResponsiveNavOpen;
    },
  },
};
</script>


<style scoped>

</style>