<template>
  <test/>
</template>

<script>
import test from './views/accueil.vue'


export default {
  name: 'App',
  mounted(){
     document.title = "CONCEPT GÉNÉRATION";
  },
  components: {
    test
  } 
}


</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
</style>
